import { request } from './request' // 引入封装好的axios对象
import store from '../store'



// 手机号登录user
export function testPost() {
  return request({
    url: '/api/order/order_pay?id=34',
	//url: '/api/user/vPayCallback?payId=V2023081162984301&param=1016&type=2&price=3&reallyPrice=3&sign=29efdd6c49a0939c126ac22970cdc330',
    method: 'post',
    params: {}
  })
}


// 手机号登录user
export function login(account, password) {
  return request({
    url: '/api/login/login',
    method: 'post',
    params: {
      account,
      password
    }
  })
}
// 仓库列表
export function loadstoragebygoods(params) {
  return request({
    url: '/api/goods/loadstoragebygoods',
    method: 'post',
    params
  })
}
// 支持平台
export function loadPlatByStorage(params) {
  return request({
    url: '/api/goods/loadPlatByStorage',
    method: 'post',
    params
  })
}
// 获取的确
export function getAllCity(params) {
  return request({
    url: 'api/goods/get_all_city',
    method: 'post',
    params
  })
}
// 地区筛选快递
export function loadDeliverByCity(params) {
  return request({
    url: '/api/goods/loadDeliverByCity',
    method: 'post',
    params
  })
}
// 商品筛选
export function searchGs(params) {
  return request({
    url: '/api/goods/search_gs',
    method: 'post',
    params
  })
}

// 手机验证码登录
export function mobilelogin(mobile, captcha) {
  return request({
    url: '/api/login/mobilelogin',
    method: 'post',
    params: {
      mobile,
      captcha
    }
  })
}

// 获取手机注册验证码
export function getCode(mobile, event) {
  return request({
    url: '/api/sms/send',
    method: 'post',
    params: {
      mobile,
      event
    }
  })
}
// 划完手势
// eslint-disable-next-line camelcase
export function checkCap(mobile, event, cap_url, cap_token, wx, qq, changemobile) {
  return request({
    url: '/api/sms/check_cap',
    method: 'post',
    params: {
      mobile,
      event,
      cap_url,
      cap_token,
      wx,
      qq,
      changemobile
    }
  })
}

// 验证图片验证码
export function captchaCode(imgCaptcha) {
  return request({
    url: '/api/login/getImages',
    method: 'post',
    params: {
      captcha: imgCaptcha
    }
  })
}

// 注册
// eslint-disable-next-line camelcase
export function register(mobile, password, wx, qq, code, key, user_id) {
  return request({
    url: '/api/login/register',
    method: 'post',
    params: {
      mobile,
      password,
      code,
      wx,
      qq,
      key,
      user_id
    }
  })
}

// 重置密码
export function chongzhi(mobile, newpassword, captcha) {
  return request({
    url: '/api/login/resetpwd',
    method: 'post',
    params: {
      mobile,
      newpassword,
      captcha
    }
  })
}

// 会员专属价格
export function deliverPrice() {
  return request({
    url: '/api/user/deliver_price',
    method: 'post',
    params: {
    }
  })
}
// 会员专属价格 /api/login/groupList
export function groupList() {
  return request({
    url: '/api/user/groupList',
    method: 'post',
    params: {
    }
  })
}

// 会员没实名认证发快递限制 /api/user/noauthSendnum
export function getNoauthSendnum() {
  return request({
    url: '/api/user/noauthSendnum',
    method: 'post',
    params: {
    }
  })
}
/*
export function getVerifyToken() {
  return request({
    url: '/api/user/getVerifyToken',
    method: 'post',
    params: {
    }
  })
}
*/
// 获取实名认证二维码
export function checkTruename() {
  return request({
    url: '/api/user/checkTruename',
    method: 'post',
    params: {
    }
  })
}
// 设置用户真实姓名，身份证
export function setUserinfo(params) {
  return request({
    url: '/api/user/setUserinfo',
    method: 'post',
    params
  })
}
// 获取实名认证二维码
export function getAuthQrcode() {
  return request({
    url: '/api/user/getAuthQrcode',
    method: 'post',
    params: {
    }
  })
}
// 获取实名认证二维码
export function getQrcodePrice(params) {
  return request({
    url: '/api/user/getPriceList',
    method: 'post',
    params
  })
}

// 首页商品列表
export function getIndexGoods(params) {
  return request({
    url: '/api/Index/get_goods_info',
    method: 'post',
    params
  })
}
// 商品分类
export function goodsCategory(params) {
  return request({
    url: '/api/goods/goods_cate',
    method: 'post',
    params
  })
}

// 所有仓库信息/api/goods/get_all_storage
export function getAllStorage(params) {
  return request({
    url: '/api/goods/get_all_storage',
    method: 'post',
    params
  })
}

// 根据平台筛选仓库快递	 testarea 2023/3/23
export function chooseDeliver(platform, key, is_nosource) {
  return request({
    //url: '/api/goods/choose_deliver',
	url: '/api/goods/choose_storage',
    method: 'post',
    params: {
      platform, key, is_nosource
    }
  })
}
// 取得快递的仓库		 testarea 2023/3/23
/*
export function chooseStorage(platform, deliver_tag) {
  return request({
    url: '/api/goods/choose_storage',
    method: 'post',
    params: {
      platform, deliver_tag
    }
  })
}
*/
// 根据平台筛选仓库商品	 testarea 2024/3/8
export function chooseStorageGoods(platform) {
  return request({
    //url: '/api/goods/choose_deliver',
	url: '/api/goods/choose_storage_goods',
    method: 'post',
    params: {
      platform
    }
  })
}
// 根据平台筛选仓库
// 原系统使用函数 testarea 2023/3/23
export function choosePlatform(platform, key) {
  return request({
    url: '/api/goods/choose_platform',
    method: 'post',
    params: {
      platform, key
    }
  })
}

// 所有商品
export function getAllGoods(params) {
  return request({
    url: '/api/goods/get_all_goods',
    method: 'post',
    params
  })
}

// 商品筛选	testarea
export function storageGoods(params) {
  return request({
    url: '/api/goods/search_gs',
    method: 'post',
    params
  })
}

// 商品筛选
export function goodsSearchGs(params) {
  return request({
    url: '/api/goods/search_gs',
    method: 'post',
    params
  })
}
// 获取电商平台对应的 打印服务
export function getPrintsofts(platform) {
  return request({
    url: '/api/store/getPrintSofts',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params: { platform }
  })
}
// 9.24日重新授权绑定店铺platform: 1（0=tb，1=jd，2=ali, 3=pdd，4=dy
export function permitBind(params) {
  return request({
    url: '/api/store/accredi',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

// 淘宝店铺绑定
export function storeBind() {
  return request({
    url: '/api/tbbandstore/permit',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 获取认证店铺信息
export function getAuthStore(params) {
  return request({
    url: '/api/tbbandstore/getAuthstore',
    method: 'post',
//    headers: {
//      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
//    },
    params
  })
}

// 获取店铺绑定信息
export function getBandStore(params) {
  return request({
    url: '/api/tbbandstore/getUserstore',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 获取店铺绑定信息成功和失败传递参数
export function getPddInfo(params) {
  return request({
    url: '/api/order/yuntuGetShopInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 获取店铺绑定信息成功和失败传递参数
export function getPddInfos(params) {
  return request({
    url: '/api/order/yuntuGetShopInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}

export function getPddInfop(params) {
  return request({
    url: '/api/order/yuntuGetShopInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    },
    params
  })
}
// 删除店铺绑定
export function getBandDelete(params) {
  return request({
    url: '/api/tbbandstore/storeDelete',
    method: 'post',
    params
  })
}

// 获取店铺名称
export function uploadImg(data) {
  return request({
    url: '/api/aiauth/uploadImg',
    method: 'post',
    data
  })
}

// 获取店铺名称
export function getStoreName(data) {
  return request({
    url: '/api/tbbandstore/getBandStore',
    method: 'post',
    data
  })
}

// 根据淘宝店铺名称获取订单列表
export function getTaoBaoOrderList(data) {
  return request({
    url: '/api/store/shopOrde',
    method: 'post',
    data
  })
}
// 根据拼多多店铺名称获取订单列表
export function getpddOrderList(data) {
  return request({
    url: '/api/bandstore/getOrderList',
    method: 'post',
    data
  })
}
// 根据拼多多店铺名称获取订单列表
export function getAllOrderList(data) {
  return request({
    url: '/api/order/getShopOrder',
    method: 'post',
    data
  })
}

// 改变店铺自动发货状态
export function autoChange(data) {
  return request({
    url: '/api/tbbandstore/dev_button',
    method: 'post',
    data
  })
}
// 收件人地址提交
export function getDisAddres(params) {
  return request({
    url: '/api/order/disposeAddress',
    method: 'post',
    params
  })
}
// 创建订单和包裹
export function createOrder(data) {
  return request({
    url: '/api/order/create_order',
    method: 'post',
    data
  })
}
// 无货源 创建订单和包裹
/*
export function createOrderNosource(data) {
  return request({
    url: '/api/order/create_order_nosource',
    method: 'post',
    data
  })
}
*/

// 发件地址添加
export function addArea(data) {
  return request({
    url: '/api/area/area_add',
    method: 'post',
    data
  })
}

// 发件地址查询
export function getAreaList() {
  return request({
    url: '/api/area/area_list',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 获取订单列表
export function getOrderList(params) {
  return request({
    url: '/api/order/get_order_list',
    method: 'post',
    params
  })
}
// 包裹列表
export function parceList(params) {
  return request({
    url: '/api/order/get_all_package',
    method: 'post',
    params
  })
}

// 首页广告位
export function getAdsInfo(params) {
  return request({
    url: '/api/Index/get_ads_info',
    method: 'post',
    params
  })
}

// 首页配置信息
export function getSiteInfo(params) {
  return request({
    url: '/api/Index/get_site_info',
    method: 'post',
    params
  })
}

// 配置验证码信息
export function getSitepassword(event) {
  return request({
    url: '/api/Index/get_site_info',
    method: 'post',
    params: {
      event
    }
  })
}
// 包裹筛选
export function getPackageList(params) {
  return request({
    url: '/api/order/get_package_list',
    method: 'post',
    params
  })
}
// 获取公告详情
export function getNoticeDetail(params){
  return request({
    url: '/api/notice/getNotice',
    method: 'post',
    params
  })
}
// 首页顶部公告
export function getTopNotice(params) {
  return request({
    url: '/api/notice/topNotice',
    method: 'post',
    params
  })
}
export function getNoticeAll(ids) {
  return request({
    url: '/api/notice/notice',
    method: 'post',
    params: { ids }
  })
}

// 系统公告分类筛选
export function getNoticeSelect(params) {
  return request({
    url: '/api/notice/noticeSelect',
    method: 'post',
    params
  })
}
// 系统公告分类筛选
export function getNoticeList(params) {
  return request({
    url: '/api/notice/noticeList',
    method: 'post',
    params
  })
}

// 工单写入
export function matterWrite(params) {
  return request({
    url: '/api/matter/matterWrite',
    method: 'post',
    params
  })
}

// 工单上传图片
export function imgsHome(params) {
  const fd = new FormData()
  fd.append('file', params)
  return request({
    url: '/api/matter/imgsHome',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8'
    },
    data: fd
  })
}

// 工单筛选
export function matterSelect(params) {
  return request({
    url: '/api/matter/matterSelect',
    method: 'post',
    params
  })
}

// 改变工单状态
export function matterStatus(params) {
  return request({
    url: '/api/matter/matterStatus',
    method: 'post',
    params
  })
}

// 用户基本信息
export function getUserMoney() {
  return request({
    url: '/api/login/userInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 用户充值金额奖励
export function getAward() {
  return request({
    url: '/api/user/loadGroup',
    method: 'post',
    params: { }
  })
}
// 用户昵称修改
export function getInfoNick(nickname, avatar) {
  return request({
    url: '/api/login/profile',
    method: 'post',
    params: { nickname, avatar }
  })
}
// ------------------------
// jlm
export function jlmPay(price, type) {
  return request({
    url: '/api/user/makeOrdeJlm',
    method: 'post',
    params: { price, type }
  })
}

// jlm 扫码后查询支付订单
export function jlmMsg(id) {
  return request({
    url: '/api/user/jlmState',
    method: 'post',
    params: { id }
  })
}

// ------------------------
// 支付宝面对面付
export function faceAlipay(amount) {
  return request({
    url: '/api/user/faceAlipay',
    method: 'post',
    params: { amount }
  })
}

// 支付宝面对面付 扫码后查询支付订单
export function faceMsg(orderId) {
  return request({
    url: '/api/user/faceAlipay_state',
    method: 'post',
    params: { orderId }
  })
}
// ff支付 本站支付宝扫码
export function vPayQr(price,type) {
  return request({
    url: '/api/user/vPayCreate',
    method: 'post',
    params: { price,type }
  })
}

// 扫码后查询支付订单
export function qrCheck(orderId) {
  return request({
    url: '/api/user/vPayCheck',
    method: 'post',
    params: { orderId }
  })
}
// -------------------
// payCode baike
/*
export function payCode(price) {
  return request({
    url: '/api/api/vPayCreate',
    method: 'post',
    params: { price }
  })
}

// 扫码后查询订单 baike 扫码
export function payCheck(orderId) {
  return request({
    url: '/api/api/vPayCheck',
    method: 'post',
    params: { orderId }
  })
}*/
// -------------------
// 余额支付
export function orderPay(id, key) {
  return request({
    url: '/api/order/order_pay',
    method: 'post',
    async: false,
    params: {
      id,
      key
    }
  })
}

// 改变订单状态
export function changeOrderStatus(params) {
  return request({
    url: '/api/order/change_order_status',
    method: 'post',
    params
  })
}

// 发件地址删除
export function areaDel(id) {
  return request({
    url: '/api/area/area_del',
    method: 'post',
    params: {
      area_id: id
    }
  })
}

// 默认发件地址选择
export function homeArea(id) {
  return request({
    url: '/api/area/homeArea',
    method: 'post',
    params: {
      area_id: id
    }
  })
}
// 首页弹窗
export function loadNoticeByPos(params) {
  return request({
    url: '/api/notice/loadNoticeByPos',
    method: 'post',
    params
  })
}
// 修改密码
export function resetpwd(password) {
  return request({
    url: '/api/balance/resetpwd',
    method: 'post',
    params: {
      password: password
    }
  })
}

// 修改支付宝账号
export function resetzfb(zfb, realname) {
  return request({
    url: '/api/balance/resetZfb',
    method: 'post',
    params: {
      zfb: zfb,
      real_name: realname
    }
  })
}

// 邀请的奖励信息筛选
export function rewardSelect(params) {
  return request({
    url: '/api/invite/rewardSelect',
    method: 'post',
    params
  })
}

// 符合状态的子订单数量
export function statusNum(params) {
  return request({
    url: '/api/order/status_num',
    method: 'post',
    params
  })
}

// 充值
export function topuprice(amount, payType) {
  return request({
    url: '/api/api/pay',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''

    },
    params: {
      amount,
      payType
    }
  })
}

// 工单记录
export function resolve(params) {
  return request({
    url: '/api/matter/resolve',
    method: 'post',
    params
  })
}

// 余额信息
export function moneyInfo() {
  if(!store.getters.getUserInfo)	return false;
  return request({
    url: '/api/balance/moneyInfo',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 邀请人ID
export function inviteHttp() {
  return request({
    url: '/api/invite/inviteHttp',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 邀请的用户信息筛选
export function inviteSelect(params) {
  return request({
    url: '/api/invite/inviteSelect',
    method: 'post',
    params
  })
}

// 邀请的全部用户信息
export function selectAll() {
  return request({
    url: '/api/invite/selectAll',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}
// 邀请海报
export function postInvite() {
  return request({
    url: '/api/invite/invitePoster',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 修改手机号
export function changemobile(mobile, captcha) {
  return request({
    url: '/api/user/changemobile',
    method: 'post',
    params: {
      mobile,
      captcha
    }
  })
}

// 金钱变动列表
export function moneylist(params) {
  return request({
    url: '/api/balance/moneylist',
    method: 'post',
    params
  })
}

// 引导用户登录授权
export function shouquan() {
  return request({
    url: '/api/agiso/shouquan',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''
    }
  })
}

// 提现
export function balanceApply(params) {
  return request({
    url: '/api/balance/apply',
    method: 'post',
    params
  })
}

// 表格导入
export function importExcel(params) {
  const fd = new FormData()
  fd.append('file', params)
  return request({
    url: '/api/excel/import_excel',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data; charset=UTF-8'
    },
    data: fd
  })
}

// 快递停发区域
export function stopArea(id) {
  return request({
    url: '/api/area/stoparea',
    method: 'post',
    params: {
      deliver_id: id
    }
  })
}

// 常见工单问题
export function matterResolve() {
  return request({
    url: '/api/matter/matterResolve',
    method: 'post'
  })
}
// 广告位
export function getsuperadsbytag(params) {
  return request({
    url: '/api/Superads/getsuperadsbytag',
    method: 'post',
    params
  })
}
// 提交订单充值
export function getSubTrad(params) {
  return request({
    url: '/api/user/subTrade',
    method: 'post',
    headers: {
      token: store.getters.getUserInfo ? store.getters.getUserInfo.token : ''

    },
    params
  })
}
