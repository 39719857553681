<template>
  <div id="app">
    <!-- 网站标题  + 路由 -->
    <router-view v-wechat-title="$route.meta.title + ' ' + this.seo_title + ' ' + this.seo_subtitle" v-if="isRouterAlive" />
    <!-- <router-view v-wechat-title="$route.meta.title + ' ' + $store.getters.getSiteInfo.name" /> -->
  </div>
</template>

<script>
import { getSiteInfo } from 'network/api'
// import axios from 'axios'
export default {
  name: 'async',
  metaInfo() {
    return {
      meta: this.metaData
    }
  },
  data() {
    return {
      keys: null,
      seo_title: '',
      seo_subtitle: '',
      seo_desc: '',
      seo_keyword: '',
      metaData: null,
      isRouterAlive: true // 全局刷新 ,
    }
  },

  created() {
    // localStorage.removeItem('code')
    // localStorage.removeItem('siteInfo')
    // axios.get('../key.json').then(res => {
    //   if (res.status === 200) {
    //     this.keys = res.data.data[0].key
    //     // eslint-disable-next-line no-unused-vars
    //     const code = res.data.data[0].key
    //     if (code !== '') {
    //       localStorage.setItem('code', this.keys)
    //     }
    // 获取配置信息 并存入vuex
    getSiteInfo({ key: this.keys }).then(res => {
      if (res.code === 1) {
        res.data.seo_title = res.data.name
        this.$store.dispatch('getSiteInfo', res.data)
        this.seo_desc = res.data.seo_desc
        this.seo_keyword = res.data.seo_keyword
        this.seo_title = res.data.seo_title
        this.seo_subtitle = res.data.seo_subtitle
        this.$nextTick(() => {
          this.metaData = [
            {
              name: this.seo_desc,
              content: this.seo_keyword
            }
          ]
        })
      } else {
        return this.$message.warning(res.msg)
      }
    })
      // } else {
      //   this.keys = []
      // }
    // })
      .catch(err => {
        console.log(err)
      })
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  mounted() {
    setTimeout(() => {
      this.metaData = [
        {
          desc: this.seo_desc,
          keyword: this.seo_keyword
        }
      ]
    }, 1000)
  }
}
</script>

<style></style>
