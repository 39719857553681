import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './assets/icon/iconfont.css'
import 'styles/base/base.css'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
import VueWechatTitle from 'vue-wechat-title'
import VueClipboard from 'vue-clipboard2'
import MetaInfo from 'vue-meta-info'
import ErrorPlugin from '../errorPlugin'
import router from './router'
import axios from 'axios'

Vue.prototype.$http = axios
// 全局修改默认配置，点击空白处不能关闭弹窗
// ElementUI.Dialog.props.closeOnClickModal.default = false
// 全局修改默认配置，按下ESC不能关闭弹窗
// ElementUI.Dialog.props.closeOnPressEscape.default = false
Vue.use(VueWechatTitle)
Vue.use(ErrorPlugin)
Vue.use(MetaInfo)
Vue.config.productionTip = false
Vue.prototype.$url = 'http://sapi.cngift.top'

const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem('appVersion')
// eslint-disable-next-line eqeqeq
if (VUE_APP_VERSION != vers) {
  localStorage.removeItem('appVersion')
  window.localStorage.setItem('appVersion', VUE_APP_VERSION)
  location.reload()
}

Vue.use(ElementUI)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
